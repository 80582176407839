import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { View, ScrollView } from 'react-native';

import { Body, H1, H2 } from '../components/Typography';
import { FeedItem } from '../components/FeedItem';

import styled from 'styled-components/native';
import breakpoint from '../constants/breakpoints';
import { useMediaQuery } from 'react-responsive';

const GET_COMPLETIONS = gql`
  query GET_COMPLETIONS {
    allCompletions(orderBy: { completionDate: desc }) {
      id
      completionDate
      user {
        id
        name
        profilePicture {
          publicUrlTransformed
        }
      }
      action {
        id
        title
        image {
          publicUrlTransformed
        }
      }
      kudos {
        id
      }
    }
  }
`;

const Parent = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  ${({ tablet }) =>
    tablet === true &&
    `
    flex-direction: row;
    flex-wrap: wrap;
  `}
`;

const Child = styled.View`
  padding: 10px;
  width: 100%;
  ${({ tablet }) =>
    tablet === true &&
    `
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    flex: 0 0 25%;
    max-width: 25%;
  `}
  ${({ XXLDesktop }) =>
    XXLDesktop === true &&
    `
    flex: 0 0 20%;
    max-width: 20%;
  `}
`;

const Feed = ({ navigation }) => {
  const { loading, error, data = {} } = useQuery(GET_COMPLETIONS);
  const isTablet = useMediaQuery({ query: breakpoint.device.md });
  const isDesktop = useMediaQuery({ query: breakpoint.device.xl });
  const isXXLDesktop = useMediaQuery({ query: breakpoint.device.xxl });

  if (loading) return <Body>Loading...</Body>;
  if (error) return <Body>Error! ${error.message}</Body>;

  const { allCompletions } = data;

  return (
    <View style={{ paddingHorizontal: 10, height: '100%' }}>
      <ScrollView>
        <Parent tablet={isTablet}>
          {allCompletions.map((completion) => (
            <Child tablet={isTablet} desktop={isDesktop} XXLDesktop={isXXLDesktop} key={completion.id}>
              <FeedItem completion={completion} navigation={navigation} />
            </Child>
          ))}
        </Parent>
      </ScrollView>
    </View>
  );
};

export { Feed, GET_COMPLETIONS };
