import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { View } from 'react-native';
import gql from 'graphql-tag';
import React from 'react';

import { Button } from '../components/Button';
import { Input } from '../components/Input';

import breakpoint from '../constants/breakpoints';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/native';

const FORGOT_PASSWORD_MUTATION = gql`
  mutation FORGOT_PASSWORD_MUTATION($email: String!) {
    sendUserPasswordResetLink(email: $email) {
      code
      message
    }
  }
`;

const Parent = styled.View`
  display: block;
  margin-top: 10px;
  marginleft: 50%;
  marginright: 50%;
  ${({ tablet }) =>
    tablet === true &&
    `
    flex-wrap: wrap;
  `}
`;

const Child = styled.View`
  padding: 10px;
  width: 100%;
  ${({ tablet }) =>
    tablet === true &&
    `
    position: relative;
    right: 0%;
    margin: 0 auto 0 auto;

    max-width: 50%;
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    position: relative;
    margin: 0 auto 0 auto;
    max-width: 25%;
  `}
`;

const ForgotPassword = ({ navigation }) => {
  const [forgotPassword] = useMutation(FORGOT_PASSWORD_MUTATION, {
    onCompleted: (data) => {
      if (data.sendUserPasswordResetLink == null) {
        navigation.navigate('Change Password');
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });
  const isTablet = useMediaQuery({ query: breakpoint.device.md });
  const isDesktop = useMediaQuery({ query: breakpoint.device.xl });

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values) => {
        await forgotPassword({ variables: values }).catch((error) => alert(error));
      }}
      validate={(values) => {
        if (values.email === '') {
          alert('Please enter your email.');
          return;
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleChange, handleSubmit, values }) => (
        <View>
          <Parent tablet={isTablet}>
            <Child tablet={isTablet} desktop={isDesktop}>
              <Input label="E-mail" autoCapitalize="none" onChangeText={handleChange('email')} value={values.email} />
              <Button title="Reset" onPress={handleSubmit} disabled={values.email === ''} />
            </Child>
          </Parent>
        </View>
      )}
    </Formik>
  );
};

export { ForgotPassword };
