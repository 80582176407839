import React from 'react';
import CuratorWidget from '../components/CuratorWidget';
import { ScrollView } from 'react-native-gesture-handler';

const Social = () => {
  return (
    <ScrollView style={{ padding: 20, height: '100%', fontFamily: 'OpenSans_400Regular' }}>
      <CuratorWidget feedId="a58c84cf-4c37-4ceb-a593-5edb0454c670" />
    </ScrollView>
  );
};

export { Social };
