import { Image, FlatList, Text, View, TouchableOpacity } from 'react-native';
import React from 'react';
import { sdgs } from '../constants/sdgs';
import { useQuery, gql } from '@apollo/client';

import styled from 'styled-components/native';
import breakpoint from '../constants/breakpoints';
import { useMediaQuery } from 'react-responsive';
import { ScrollView } from 'react-native-gesture-handler';

const ALL_SDGS_QUERY = gql`
  query {
    allSdgs {
      sdgNo
      title
      description
      content {
        document
      }
    }
  }
`;
const Parent = styled.View`
  display: grid;
  grid-template-columns: repeat(auto-fill, 50%);
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  align-items: left;
  flex-wrap: wrap;
  margin-top: 10px;

  ${({ tablet }) =>
    tablet === true &&
    `
    grid-template-columns: repeat(auto-fill, 25%);
    
  `}

  ${({ desktop }) =>
    desktop === true &&
    `
    grid-template-columns: repeat(auto-fill, 20%);
  `}
`;

const Child = styled.View`
  padding: 10px;
  margin: 0 auto;
  align-items: center;
  width: 20%;
  flex: 50%;
  ${({ mobile }) =>
    mobile === true &&
    `
    flex: 50%;
  `}

  ${({ tablet }) =>
    tablet === true &&
    `
    flex: 25%;
    
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    flex: 20%;
  `}
`;

const Item = ({ sdg, navigation }) => (
  <TouchableOpacity
    style={{
      height: 150,
      width: 150,
      marginBottom: 10,
    }}
    onPress={() => navigation.navigate('SDG', { sdg })}
  >
    <Image
      source={sdg.image}
      style={{
        height: 150,
        width: 150,
        borderRadius: 30,
      }}
    />
  </TouchableOpacity>
);

const Sdgs = ({ navigation }) => {
  const { loading, error, data } = useQuery(ALL_SDGS_QUERY);
  const isMobile = useMediaQuery({ query: breakpoint.device.vs });
  const isTablet = useMediaQuery({ query: breakpoint.device.md });
  const isDesktop = useMediaQuery({ query: breakpoint.device.lg });
  if (loading) return <Text>Loading</Text>;
  if (error) return <Text>Error! ${error.message}</Text>;
  console.log(data);
  const mergedSdgs = sdgs.map((t1) => ({ ...t1, ...data.allSdgs.find((t2) => t2.sdgNo === t1.sdgNo) }));

  // Merge the sdg data from the server with the hard-coded sdg data.
  //const mergedSdgs = sdgs.map((t1) => ({ ...t1, ...data.allSdgs.find((t2) => t2.sdgNo === t1.sdgNo) }));
  return (
    (document.title = 'SDG'),
    (
      <ScrollView>
        <View>
          <Parent tablet={isTablet} desktop={isDesktop}>
            {mergedSdgs.map((onesdg) => (
              <Child tablet={isTablet} desktop={isDesktop} mobile={isMobile} key={onesdg.sdgNo}>
                <Item sdg={onesdg} navigation={navigation} />
              </Child>
            ))}
          </Parent>
        </View>
      </ScrollView>
    )
  );
};

export { Sdgs };
