import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { View } from 'react-native';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Ionicons } from '@expo/vector-icons';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { colors } from '../constants/colors';
import { Body } from '../components/Typography';


import breakpoint from '../constants/breakpoints';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/native';

const CHANGE_PASSWORD_MUTATION = gql`
mutation CHANGE_PASSWORD_MUTATION($email: String!, $token: String!, $password: String!) {
  redeemUserPasswordResetToken(
    email: $email
    token: $token
    password: $password
  ) {
    code
    message
  }
}
`;

const Parent = styled.View`
  display: block;
  margin-top: 10px;
  marginleft: 50%;
  marginright: 50%;
  ${({ tablet }) =>
    tablet === true &&
    `
    flex-wrap: wrap;
  `}
`;

const Child = styled.View`
  padding: 10px;
  width: 100%;
  ${({ tablet }) =>
    tablet === true &&
    `
    position: relative;
    right: 0%;
    margin: 0 auto 0 auto;

    max-width: 50%;
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    position: relative;
    margin: 0 auto 0 auto;
    max-width: 25%;
  `}
`;

const ChangePassword = ({ navigation }) => {
  const [hidePassword, setHidePassword] = useState(true);
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION, {
    onCompleted: (data) => {
      if (data.redeemUserPasswordResetToken == null) {
        navigation.navigate('Sign In');
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });
  const isTablet = useMediaQuery({ query: breakpoint.device.md });
  const isDesktop = useMediaQuery({ query: breakpoint.device.xl });

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values) => {
        await changePassword({ variables: values }).catch((error) => alert(error));
      }}
      validate={(values) => {
        if (values.email === '') {
          alert('Please enter your email.');
          return;
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleChange, handleSubmit, values }) => (
        <View>
          <Parent tablet={isTablet}>
            <Child tablet={isTablet} desktop={isDesktop}>
              <Body variant={3} style={{ paddingTop: 10, paddingBottom: 10 }}>
                We have sent a reset token to your email address, please paste that token below and set your new password.
              </Body>
              <Input label="Token" autoCapitalize="none" onChangeText={handleChange('token')} value={values.token} />
              <Input label="E-mail" autoCapitalize="none" onChangeText={handleChange('email')} value={values.email} />
              <Input
                label="New Password"
                secureTextEntry={hidePassword}
                autoCapitalize="none"
                onChangeText={handleChange('password')}
                value={values.password}
                secureIcon={
                  <Ionicons
                    style={{ padding: 10 }}
                    name={hidePassword ? 'ios-eye-off' : 'ios-eye'}
                    size={25}
                    color={colors.grey}
                    onPress={() => setHidePassword(!hidePassword)}
                  />
                }
              />
              <Button
                title="Change Password"
                onPress={handleSubmit}
                disabled={values.token === '' || values.email === '' || values.password === ''}
              />
            </Child>
          </Parent>
        </View>
      )}
    </Formik>
  );
};

export { ChangePassword };
