import React, { useState } from 'react';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useUser } from '../hooks/useUser';
import { useMutation, gql } from '@apollo/client';

import { Formik } from 'formik';

import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { H2, Body } from '../components/Typography';

import styled from 'styled-components/native';
import breakpoint from '../constants/breakpoints';
import { useMediaQuery } from 'react-responsive';

const SUBMIT_IDEA_QUERY = gql`
  mutation createIdea($title: String!, $body: String!) {
    createIdea(data: { title: $title, body: $body }) {
      id
      title
      body
    }
  }
`;

const Parent = styled.View`
  display: block;
  margin-top: 10px;
  marginleft: 50%;
  marginright: 50%;
  padding: 10px;
  ${({ tablet }) =>
    tablet === true &&
    `
    flex-wrap: wrap;
  `}
`;

const Child = styled.View`
  padding-top: 10px;
  width: 100%;

  ${({ tablet }) =>
    tablet === true &&
    `
    
    right: 0%;
    margin: 0 auto 0 auto;
    max-width: 50%;
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    
    margin: 0 auto 0 auto;
    max-width: 25%;
  `}
`;

const SubmitIdea = ({ navigation }) => {
  const isTablet = useMediaQuery({ query: breakpoint.device.md });
  const isDesktop = useMediaQuery({ query: breakpoint.device.xl });
  const user = useUser();

  const [ideasubmit] = useMutation(SUBMIT_IDEA_QUERY, {
    onCompleted: () => navigation.goBack(),
  });

  var username = function name(user) {
    var fullName = user.name.split(' '),
      firstName = fullName[0].toLowerCase();
    firstName = capitalizeFirstLetter(firstName);
    return firstName;
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div>
      <Formik
        initialValues={{ title: '', body: '' }}
        onSubmit={async (values) => {
          if (values.body != '' && values.title != '') {
            await ideasubmit({ variables: values }).catch((error) => alert(error));
          }
        }}
        validate={(values) => {
          if (values.title === '') {
            alert('Please enter a title. ');
            return;
          }
          if (values.body === '') {
            alert('Please enter your message.');
            return;
          }
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleChange, handleSubmit, values }) => (
          <KeyboardAwareScrollView keyboardShouldPersistTaps="always">
            <View>
              <Parent tablet={isTablet}>
                <Child tablet={isTablet} desktop={isDesktop}>
                  <H2>Hi {username(user)},</H2>
                  <Body variant={3} style={{ paddingTop: 10, paddingBottom: 10 }}>
                    If you have an idea for new actions that we should create, submit them here, we'd love to hear from
                    you. Suggestions about how we can improve your experience are welcome!
                  </Body>
                </Child>
                <View>
                  <Child tablet={isTablet} desktop={isDesktop}>
                    <Input
                      label="What is your message title?"
                      autoCapitalize="none"
                      onChangeText={handleChange('title')}
                      value={values.title}
                    />
                    <Input
                      label="Tell us more:"
                      autoCapitalize="none"
                      onChangeText={handleChange('body')}
                      value={values.body}
                    />
                    <Button title="Submit" disabled={values.title == '' || values.body == ''} onPress={handleSubmit} />
                  </Child>
                </View>
              </Parent>
            </View>
          </KeyboardAwareScrollView>
        )}
      </Formik>
    </div>
  );
};

export { SubmitIdea };
