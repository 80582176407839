import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Chip } from 'react-native-paper';
import { View, ScrollView, SwipeableListView } from 'react-native';

import styled from 'styled-components/native';
import { Checkbox } from '../components/Checkbox';
import { Button } from '../components/Button';
import { Body, H2 } from '../components/Typography';
import { colors } from '../constants/colors';
import { useUser } from '../hooks/useUser';
import { sdgs } from '../constants/sdgs';

const GET_USER_ACTIONS = gql`
  query GetUserActions($id: ID!) {
    allActions {
      id
      title
      image {
        publicUrlTransformed
      }
      content {
        document
      }
      relatedSdgs {
        sdgNo
      }
      categories {
        title
      }
    }
    allCompletions(where: { user: { id: $id } }) {
      action {
        id
      }
      user {
        id
      }
    }
    allCategories {
      title
    }
  }
`;

const ActionWrapper = styled.View`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  height: 100%;
`;

function Actions({ navigation }) {
  const user = useUser();
  const [sdgFilter, setSdgFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);

  const { loading, error, data = {} } = useQuery(GET_USER_ACTIONS, { variables: { id: user.id } });

  const [todoTab, setTab] = useState(true);

  const swapView = () => {
    setTab(!todoTab);
  };

  if (loading) return <Body>Loading...</Body>;
  if (error) return <Body>Error! ${error.message}</Body>;

  const { allActions, allCompletions, allCategories } = data;
  const myCompletions = allCompletions.map((completion) => completion.action?.id);

  if (todoTab == true) {
    //return todo
    return (
      <ActionWrapper>
        <H2 variant={3} style={{ padding: 10, fontSize: 20 }}>
          Filter by Category
        </H2>
        <View style={{ marginBottom: 5, flexDirection: 'row' }}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {allCategories.map((category) => (
              <Chip
                key={category.title}
                mode="outlined"
                height={30}
                textStyle={{ color: colors.black, fontSize: 14, fontFamily: 'OpenSans_600SemiBold' }}
                selected={category.title === categoryFilter}
                style={{
                  alignItems: 'center',
                  backgroundColor: category.title === categoryFilter ? colors.yellow : colors.grey,
                  margin: 5,
                }}
                onClose={category.title === categoryFilter ? () => setCategoryFilter(null) : null}
                onPress={() => {
                  setCategoryFilter(category.title);
                }}
              >
                {category.title}
              </Chip>
            ))}
          </ScrollView>
        </View>
        <H2 variant={3} style={{ padding: 10, fontSize: 20 }}>
          Filter by SDG
        </H2>
        <View style={{ marginBottom: 10, flexDirection: 'row' }}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {sdgs.map((item, index) => {
              return (
                <Chip
                  key={index}
                  mode="outlined"
                  height={30}
                  textStyle={{ color: colors.white, fontSize: 14, fontFamily: 'OpenSans_600SemiBold' }}
                  selected={item.sdgNo === sdgFilter}
                  style={{
                    backgroundColor: item.color,
                    alignItems: 'center',
                    marginHorizontal: 5,
                  }}
                  onClose={item.sdgNo === sdgFilter ? () => setSdgFilter(null) : null}
                  onPress={() => {
                    setSdgFilter(item.sdgNo);
                  }}
                >
                  {'SDG ' + item.sdgNo}
                </Chip>
              );
            })}
          </ScrollView>
        </View>
        <View
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingVertical: 10,
            marginTop: 5,
            borderTopColor: '#e5e5e5',
            borderTopWidth: 1,
          }}
        >
          <View
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <H2 variant={3} style={{ padding: 10, fontSize: 20 }}>
              To-do
            </H2>
            <Chip
              mode="outlined"
              height={30}
              textStyle={{ color: colors.black, fontSize: 14, fontFamily: 'OpenSans_600SemiBold' }}
              style={{ marginHorizontal: 10, height: 34 }}
              onPress={swapView}
            >
              {'See Completed'}
            </Chip>
          </View>
        </View>
        <ScrollView>
          {allActions
            .filter((action) => {
              const relatedSdgIds = action.relatedSdgs.map((sdg) => sdg.sdgNo); //returns array of the relatedSdgs ids
              const relatedCategoryNames = action.categories.map((category) => category.title); //returns array of the relatedCategory titles
              const isCompleted = myCompletions.includes(action.id);

              if (!isCompleted) {
                if (sdgFilter && categoryFilter && !isCompleted) {
                  // if the relatedSdgs includes the current sdgs filter
                  if (relatedSdgIds.includes(sdgFilter) && relatedCategoryNames.includes(categoryFilter)) return action;
                } else if (sdgFilter) {
                  if (relatedSdgIds.includes(sdgFilter)) return action;
                } else if (categoryFilter) {
                  // if the relatedCategoryNames includes the current category filter
                  if (relatedCategoryNames.includes(categoryFilter)) return action;
                } else {
                  return action;
                }
              }
            })
            .map((action) => {
              const isCompleted = myCompletions.includes(action.id);

              return (
                <Checkbox
                  key={action.id}
                  title={action.title}
                  isCompleted={isCompleted}
                  onPress={() => navigation.navigate('Action', { action, isCompleted })}
                />
              );
            })}
        </ScrollView>
      </ActionWrapper>
    );
  } else {
    //return completed
    return (
      <ActionWrapper>
        <H2 variant={3} style={{ padding: 10, fontSize: 20 }}>
          Filter by Category
        </H2>
        <View style={{ marginBottom: 5, flexDirection: 'row' }}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {allCategories.map((category) => (
              <Chip
                key={category.title}
                mode="outlined"
                height={30}
                textStyle={{ color: colors.black, fontSize: 14, fontFamily: 'OpenSans_600SemiBold' }}
                selected={category.title === categoryFilter}
                style={{
                  alignItems: 'center',
                  backgroundColor: category.title === categoryFilter ? colors.yellow : colors.grey,
                  margin: 5,
                }}
                onClose={category.title === categoryFilter ? () => setCategoryFilter(null) : null}
                onPress={() => {
                  setCategoryFilter(category.title);
                }}
              >
                {category.title}
              </Chip>
            ))}
          </ScrollView>
        </View>
        <H2 variant={3} style={{ padding: 10, fontSize: 20 }}>
          Filter by SDG
        </H2>
        <View style={{ marginBottom: 10, flexDirection: 'row' }}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {sdgs.map((item, index) => {
              return (
                <Chip
                  key={index}
                  mode="outlined"
                  height={30}
                  textStyle={{ color: colors.white, fontSize: 14, fontFamily: 'OpenSans_600SemiBold' }}
                  selected={item.sdgNo === sdgFilter}
                  style={{
                    backgroundColor: item.color,
                    alignItems: 'center',
                    marginHorizontal: 5,
                  }}
                  onClose={item.sdgNo === sdgFilter ? () => setSdgFilter(null) : null}
                  onPress={() => {
                    setSdgFilter(item.sdgNo);
                  }}
                >
                  {'SDG ' + item.sdgNo}
                </Chip>
              );
            })}
          </ScrollView>
        </View>
        <View
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingVertical: 10,
            marginTop: 5,
            borderTopColor: '#e5e5e5',
            borderTopWidth: 1,
          }}
        >
          <View
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <H2 variant={3} style={{ padding: 10, fontSize: 20 }}>
              Completed
            </H2>
            <Chip
              mode="outlined"
              textStyle={{ color: colors.black, fontSize: 14, fontFamily: 'OpenSans_600SemiBold' }}
              style={{ marginHorizontal: 10, height: 34 }}
              onPress={swapView}
            >
              {'See To-do'}
            </Chip>
          </View>
        </View>
        <ScrollView>
          {allActions
            .filter((action) => {
              const relatedSdgIds = action.relatedSdgs.map((sdg) => sdg.sdgNo); //returns array of the relatedSdgs ids
              const relatedCategoryNames = action.categories.map((category) => category.title); //returns array of the relatedCategory titles
              const isCompleted = myCompletions.includes(action.id);

              if (isCompleted) {
                if (sdgFilter && categoryFilter && isCompleted) {
                  // if the relatedSdgs includes the current sdgs filter
                  if (relatedSdgIds.includes(sdgFilter) && relatedCategoryNames.includes(categoryFilter)) return action;
                } else if (sdgFilter) {
                  if (relatedSdgIds.includes(sdgFilter)) return action;
                } else if (categoryFilter) {
                  // if the relatedCategoryNames includes the current category filter
                  if (relatedCategoryNames.includes(categoryFilter)) return action;
                } else {
                  return action;
                }
              }
            })
            .filter((action) => {
              const isCompleted = myCompletions.includes(action.id);

              if (isCompleted) {
                return action;
              }
            })
            .map((action) => {
              const isCompleted = myCompletions.includes(action.id);

              return (
                <Checkbox
                  key={action.id}
                  title={action.title}
                  isCompleted={isCompleted}
                  onPress={() => navigation.navigate('Action', { action, isCompleted })}
                />
              );
            })}
        </ScrollView>
      </ActionWrapper>
    );
  }
}

export { Actions, GET_USER_ACTIONS };
