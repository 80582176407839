import React from 'react';
import styled from 'styled-components/native';
import { Button } from '../components/Button';
import breakpoint from '../constants/breakpoints';
import { useMediaQuery } from 'react-responsive';
import { Body, Heading } from '../components/Typography';
import { View, Image } from 'react-native';
import { colors } from '../constants/colors';
import { DocumentRenderer } from '../components/DocumentRenderer';
import { FacebookIcon } from 'react-share';

const Header = styled.View``;

const HeroDiv = styled.View`
  height: 200px;
  ${({ tablet }) =>
    tablet === true &&
    `
    height: 400px;
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    height: 550px;
  `}
`;

const Content = styled.View`
  justify-content: center;
`;

const ExploreButtons = styled.View``;

const BlockWrapper = styled.View`
  padding: 3vh 4vw 1vh;
  ${({ desktop }) =>
    desktop === true &&
    `
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
  `}
`;

const BlockWrapperReverse = styled.View`
  padding: 3vh 4vw 3vh;
  ${({ desktop }) =>
    desktop === true &&
    `
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
  `}
`;

const LeftText = styled.View`
  ${({ desktop }) =>
    desktop === true &&
    `
    flex: 0 0 48%;
    max-width: 48%;
  `}
`;

const RightText = styled.View`
  ${({ desktop }) =>
    desktop === true &&
    `
    flex: 0 0 48%;
    max-width: 48%;
  `}
`;

const ActionsDiv = styled.View`
  height: auto;
  margin: 4vh auto 16vh auto;
  max-width: 90%;
`;

const LeftImgDiv = styled.View`
  height: 300px;
  ${({ tablet }) =>
    tablet === true &&
    `
    height: 400px;
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    height: 450px;
    flex: 0 0 48%;
    max-width: 48%;
  `}
`;

const RightImgDiv = styled.View`
  height: 300px;
  ${({ tablet }) =>
    tablet === true &&
    `
    height: 400px;
  `}
  ${({ desktop }) =>
    desktop === true &&
    `
    height: 450px;
    flex: 0 0 48%;
    max-width: 48%;
  `}
`;

const Footer = styled.View`
  background-color: #343642;
  padding: 8vh 4vw 3vh;
  ${({ desktop }) =>
    desktop === true &&
    `
    flex-direction: row;
    justify-content: space-evenly;
  `}
`;

const FooterSection = styled.View`
  padding-bottom: 4vh;
  align-items: center;
  gap: 10px;
  ${({ desktop }) =>
    desktop === true &&
    `
    align-items: flex-start;
  `}
`;

const LandingPage = ({ navigation }) => {
  const isTablet = useMediaQuery({ query: breakpoint.device.md });
  const isDesktop = useMediaQuery({ query: breakpoint.device.xl });
  //font variants query for decreasing font size
  let hVariant = 1;
  let bVariant = 3;
  if (isDesktop) {
    hVariant = 1;
    bVariant = 3;
  } else if (isTablet) {
    hVariant = 2;
    bVariant = 3;
  } else {
    //mobile font
    hVariant = 3;
    bVariant = 4;
  }
  const onSignInPress = () => {
    navigation.navigate('Sign In');
  };
  const onSignUpPress = () => {
    navigation.navigate('Sign Up');
  };
  const onLinkPress = (href) => {
    window.open(href, '_blank');
  };
  return (
    <>
      <Content>
        <Header>
          <Heading
            style={{ textAlign: 'center', paddingHorizontal: '4vw', paddingTop: '3vh', paddingBottom: '1vh' }}
            variant={hVariant + 1}
          >
            Swinburne Actions for Sustainability Impact
          </Heading>
          <Heading style={{ textAlign: 'center', paddingHorizontal: '4vw', paddingBottom: '4vh' }} variant={hVariant}>
            Welcome to SA4SI's Web Application!
          </Heading>
        </Header>
        <HeroDiv tablet={isTablet} desktop={isDesktop}>
          <Image style={{ height: '100%' }} source={require('../assets/img/hero-leaf.png')}></Image>
        </HeroDiv>
        <ExploreButtons style={{ paddingHorizontal: '4vw', paddingTop: '3vh', paddingBottom: '4vh' }}>
          <Heading style={{ textAlign: 'center' }} variant={hVariant + 1}>
            Track Your Actions
          </Heading>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '4vw',
              paddingTop: '2vh',
            }}
          >
            <View style={{ marginRight: 15 }}>
              <Button title="Log In" onPress={onSignInPress} />
            </View>
            <Button title="Sign Up" onPress={onSignUpPress} />
          </View>
        </ExploreButtons>
        <View>
          <Heading style={{ textAlign: 'center' }} variant={hVariant + 1}>
            About
          </Heading>
        </View>
        <BlockWrapper tablet={isTablet} desktop={isDesktop}>
          <LeftText tablet={isTablet} desktop={isDesktop}>
            <Heading style={{ textAlign: 'center' }} variant={hVariant + 2}>
              What is SA4SI?
            </Heading>
            <Body style={{ textAlign: 'center', paddingHorizontal: '4vw', lineHeight: '1.7em' }} variant={bVariant}>
              <p>
                SA4SI is an initiative of Swinburne Business to accelerate actions towards the Sustainable Development
                Goals.
              </p>
              <p>
                As a member of our community, we are inviting you to join with us to create actions to bring about
                change to achieve the Sustainable Development Goals.
              </p>
              <p>
                Everyone can have an impact, and SA4SI provides you with opportunities to engage with responsibility and
                sustainability, either at home or other learning and engagement activities, and share those actions with
                others.
              </p>
              <p>
                SA4SI has used it’s sustainability calendar to inspire ways in which how we, as individuals, can
                actively interact with the Sustainable Development Goals in everyday life.
              </p>
              <p>
                "One action a day is a very pragmatic suggestion, and multiply that with 365 days in a year and you get
                365 SDG actions. And that's the impact of only one individual."
                <br />
                Professor Weng Marc Lim, Head of School of Business, Faculty of Business, Design and Arts, Swinburne
                Sarawak.
              </p>
            </Body>
          </LeftText>
          <RightImgDiv tablet={isTablet} desktop={isDesktop}>
            <Image style={{ height: '100%' }} source={require('../assets/img/about.png')}></Image>
          </RightImgDiv>
        </BlockWrapper>
        <BlockWrapperReverse tablet={isTablet} desktop={isDesktop}>
          <RightText tablet={isTablet} desktop={isDesktop}>
            <Heading style={{ textAlign: 'center' }} variant={hVariant + 2}>
              Why SA4SI?
            </Heading>
            <Body style={{ textAlign: 'center', paddingHorizontal: '4vw', lineHeight: '1.7em' }} variant={bVariant}>
              <p>
                "We have a once in a lifetime opportunity to achieve the world’s goals…But we cannot do it unless
                everyone plays their part."
                <br />
                H. E. Antonio Guterres, Secretary-General, United Nations.
              </p>
              <p>
                The United Nations has declared 2020 as the ‘decade of action’ of delivering on the Sustainable
                Development Goals, with universities being key contributors in the process, as the countdown progresses
                towards the implementation date of all the Sustainable Development Goals by 2030.
              </p>
              <p>
                Swinburne University remains steadfastly committed to the Sustainable Development Goals. Swinburne
                Business is an advanced signatory to the United Nations' Principles for Responsible Management Education
                (UN PRME), joining the initiative in 2018. The UN PRME is the world’s largest collective and
                relationship between the UN and business schools, and plays a key role in facilitating how business
                schools shape future responsible leaders.
              </p>
              <p>Everyone can make an impact on sustainability and never underestimate your own influence!</p>
            </Body>
          </RightText>
          <LeftImgDiv tablet={isTablet} desktop={isDesktop}>
            <Image style={{ height: '100%' }} source={require('../assets/img/why-bee.png')}></Image>
          </LeftImgDiv>
        </BlockWrapperReverse>
        <ActionsDiv tablet={isTablet} desktop={isDesktop}>
          <img src={require('../assets/img/6actions.png')}></img>
        </ActionsDiv>
      </Content>
      <Footer tablet={isTablet} desktop={isDesktop}>
        <FooterSection tablet={isTablet} desktop={isDesktop}>
          <Heading variant={hVariant + 2} style={{ color: 'white' }}>
            Swinburne Staff &amp; Students
          </Heading>
          <Body
            variant={bVariant}
            style={{ color: 'white', textDecoration: 'underline', width: 'fit-content' }}
            onPress={() => onLinkPress('https://swinburne.instructure.com/enroll/LDPP84')}
          >
            Enroll in the Canvas Module
          </Body>
          <Body
            variant={bVariant}
            style={{ color: 'white', textDecoration: 'underline', width: 'fit-content' }}
            onPress={() => onLinkPress('https://www.facebook.com/swinburnesussociety')}
          >
            Swinburne Sustainability Society
          </Body>
          <Body
            variant={bVariant}
            style={{ color: 'white', textDecoration: 'underline', width: 'fit-content' }}
            onPress={() => onLinkPress('https://www.facebook.com/swinburnesarawakgreenclub')}
          >
            Swinburne Green Club
          </Body>
        </FooterSection>
        <FooterSection tablet={isTablet} desktop={isDesktop}>
          <Heading variant={hVariant + 2} style={{ color: 'white' }}>
            Resources
          </Heading>
          <Body
            variant={bVariant}
            style={{ color: 'white', textDecoration: 'underline', width: 'fit-content' }}
            onPress={() =>
              onLinkPress(
                'https://www.swinburne.edu.au/about/our-structure/organisational-structure/schools-departments/school-business-law-entrepreneurship'
              )
            }
          >
            Business Law and Entrepreneurship
          </Body>
          <Body
            variant={bVariant}
            style={{ color: 'white', textDecoration: 'underline', width: 'fit-content' }}
            onPress={() => onLinkPress('https://www.unprme.org/swinburne-business')}
          >
            PRME SIP Report
          </Body>
          <Body
            variant={bVariant}
            style={{ color: 'white', textDecoration: 'underline', width: 'fit-content' }}
            onPress={() => onLinkPress('https://swinburne.instructure.com/enroll/LDPP84')}
          >
            PRIMETime Article
          </Body>
          <Body
            variant={bVariant}
            style={{ color: 'white', textDecoration: 'underline', width: 'fit-content' }}
            onPress={() =>
              onLinkPress(
                'https://primetime.unprme.org/2020/09/18/engaging-every-student-in-the-sdgs-every-day-swinburne-business-school-2/'
              )
            }
          >
            Swinburne Chat Series
          </Body>
        </FooterSection>
        <FooterSection tablet={isTablet} desktop={isDesktop}>
          <Heading variant={hVariant + 2} style={{ color: 'white' }}>
            Socials
          </Heading>
          <Body
            variant={bVariant}
            style={{ width: 'fit-content', paddingTop: '1vh', paddingBottom: '1vh' }}
            onPress={() => onLinkPress('https://www.facebook.com/SA4SI/')}
          >
            <FacebookIcon size={36} />
          </Body>
          <Body variant={bVariant} style={{ color: 'white' }}>
            Get involved with the #SA4SI hashtag!
          </Body>
        </FooterSection>
      </Footer>
    </>
  );
};

export { LandingPage };
