import { useMutation } from '@apollo/client';
import { View, ScrollView } from 'react-native';
import { Button } from '../components/Button';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import React from 'react';
import { isEmpty } from 'lodash';

import { CURRENT_USER_QUERY, useUser } from '../hooks/useUser';
import { ProfilePicture } from '../components/ProfilePicture';
import { Body, Subheading } from '../components/Typography';
import { colors } from '../constants/colors';

const SIGN_OUT_MUTATION = gql`
  mutation {
    endSession
  }
`;

const GET_USER_ACTIONS = gql`
  query GetUserActions($id: ID!) {
    allActions {
      id
      title
      image {
        publicUrlTransformed
      }
      content {
        document
      }
      relatedSdgs {
        sdgNo
      }
      categories {
        title
      }
    }
    allCompletions(where: { user: { id: $id } }) {
      action {
        id
      }
      user {
        id
      }
    }
    allCategories {
      title
    }
  }
`;

const Profile = ({ navigation }) => {
  const [signOut] = useMutation(SIGN_OUT_MUTATION, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
  });

  const user = useUser();
  const { loading, error, data = {} } = useQuery(GET_USER_ACTIONS, { variables: { id: user.id } });

  if (loading) return <Body>Loading...</Body>;
  if (error) return <Body>Error! ${error.message}</Body>;

  const { allCompletions } = data;
  const myCompletions = allCompletions.map((completion) => completion.action?.id);

  return (
    <View style={{ alignItems: 'center', paddingTop: 20, flex: 1 }}>
      <ScrollView>
        <View
          style={{
            alignItems: 'center',

            flex: 1,
          }}
        >
          <ProfilePicture
            name={user.name}
            source={{ uri: user.profilePicture?.publicUrlTransformed }}
            containerStyle={{ marginBottom: 10 }}
            size={100}
          />
          <Subheading bold>{user.name}</Subheading>
          <Body style={{ padding: 10, fontSize: 20 }}>{`You have completed ${_.size(myCompletions)} actions!`}</Body>
          {!isEmpty(user.biography) && (
            <>
              <Subheading bold variant={2} color={colors.blue} style={{ paddingTop: 10 }}>
                Biography
              </Subheading>
              <Body variant={3} style={{ paddingBottom: 10 }}>
                {user.biography}
              </Body>
            </>
          )}
          {!isEmpty(user.areasOfInterest) && (
            <>
              <Subheading bold variant={2} color={colors.purple} style={{ paddingTop: 10 }}>
                Areas of Interest
              </Subheading>
              <Body variant={3} style={{ paddingBottom: 10 }}>
                {user.areasOfInterest}
              </Body>
            </>
          )}
          {!isEmpty(user.funFacts) && (
            <>
              <Subheading bold variant={2} color={colors.green} style={{ paddingTop: 10 }}>
                Sustainable Fun Facts
              </Subheading>
              <Body variant={3} style={{ paddingBottom: 10 }}>
                {user.funFacts}
              </Body>
            </>
          )}
          <View style={{ paddingTop: 5 }}>
            <Button title="Submit an Idea" onPress={() => navigation.navigate('Submit an Idea')} />
          </View>
          <View style={{ marginTop: 5, marginBottom: 5 }}>
            <Button
              title="Edit Profile"
              style={{ width: 'fit-content', fontSize: 20 }}
              onPress={() => navigation.navigate('Edit Profile')}
            />
          </View>
          <Button title="Sign Out" onPress={signOut} />
          <Body
            variant={3}
            style={{ paddingTop: 20, textDecoration: 'underline', width: 'fit-content' }}
            onPress={() => {
              if (confirm('Returning to the home page will sign you out of the application. Continue?')) {
                signOut();
              }
            }}
          >
            Back to Home Page
          </Body>
        </View>
      </ScrollView>
    </View>
  );
};

export { Profile };
